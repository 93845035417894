import 'core-js/stable';
import 'regenerator-runtime/runtime';

import { createApp } from 'vue';
import SearchApp from '../../top/SearchApp';
import SearchScreen from '../../search/components/sp/SearchScreen';
import { buildSearchStore } from '../../search/store';
import { INITIALIZED } from '../../search/store/mutation-types';
import { initSlider } from '../../common/slider';

document.addEventListener('DOMContentLoaded', () => {
  // 横スクロールのボタン初期化
  initSlider();

  // 検索欄の初期化
  document.querySelectorAll('.js-top-page-search-components').forEach(el => {
    const store = buildSearchStore();
    store.commit(INITIALIZED);

    createApp(SearchApp)
      .use(store)
      .mount(el);

    const searchScreenEl = document.querySelector('.js-sp-search-screen');
    if (searchScreenEl) {
      createApp(SearchScreen)
        .use(store)
        .mount(searchScreenEl);
    }
  });

  // 「カテゴリから探す」のクリックをGA計測
  document
    .querySelectorAll('.js-category-ga-event')
    .forEach(elm =>
      elm.addEventListener('click', () =>
        sendGAEvent('サイトTOP', 'click', 'カテゴリから探す')
      )
    );
});
