<template>
  <div>
    <pc-search />
    <sp-search />
  </div>
</template>

<script>
import PcSearch from './pc/Search';
import SpSearch from './sp/Search';

export default {
  components: { PcSearch, SpSearch }
};
</script>
