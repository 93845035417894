<template>
  <div class="mobile-only">
    <trigger-button />
    <!-- trigger-buttonから起動されるsearch-screenは、body直下にあってstore経由で表示する -->
  </div>
</template>

<script>
import TriggerButton from './TriggerButton';

export default {
  components: { TriggerButton }
};
</script>
