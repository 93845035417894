// 横スクロール用の入力デバイスがあると限らないPC用に、横スクロール領域に添えるスクロールボタンの動作。
// 以下のDOM構造を想定している。
// * js-slider-container
//   * js-slider-items overflow-yなどが設定されたスクロールする領域
//     * js-slider-item スクロール要素。この要素の幅をスクロール量として使う
//   * js-slider-prev 戻る方向にスクロールするボタン
//   * js-slider-next 進む方向にスクロールするボタン
export function initSlider() {
  document.querySelectorAll('.js-slider-container').forEach(containerEl => {
    const itemsEl = containerEl.querySelector('.js-slider-items');

    // itemの最初の1つをスクロール量として使う
    const scrollSize =
      itemsEl.querySelector('.js-slider-item')?.offsetWidth || 0;

    const selectorAndDirection = {
      '.js-slider-prev': -1,
      '.js-slider-next': 1
    };
    Object.entries(selectorAndDirection).forEach(([selector, direction]) => {
      containerEl.querySelectorAll(selector).forEach(buttonEl => {
        buttonEl.addEventListener('click', () => {
          if (itemsEl.scrollBy) {
            itemsEl.scrollBy({
              behavior: 'smooth',
              left: scrollSize * direction
            });
          } else {
            // IE11用。スムーズなスクロールは諦める
            itemsEl.scrollLeft += scrollSize * direction;
          }
        });
      });
    });
  });
}
