<template>
  <div
    class="top-fv-search-window header-sp__search-trigger push-bottom--sm pc-only"
    role="toggle-next-fade"
  >
    <form
      role="ignore-empty-submit"
      action="/search/topics"
      accept-charset="UTF-8"
      method="get"
      autocomplete="off"
      @submit.prevent="handleSubmit"
    >
      <div>
        <i class="glyphs glyphs-search" aria-hidden="true"></i>
        <search-input />
        <div class="search-suggest-wrapper" v-if="search_box_active">
          <search-suggestions />
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import utils from '../../search/utils';

import SearchInput from './SearchInput';
import SearchSuggestions from '../../search/components/pc/SearchSuggestions';

export default {
  components: { SearchInput, SearchSuggestions },
  computed: {
    ...mapGetters(['search_box_active', 'current_keyword'])
  },
  mounted() {
    document.addEventListener('mousedown', this.handleClickOutside);
  },
  methods: {
    ...mapActions(['disableSearchBox']),
    handleClickOutside(e) {
      if (e.target && !this.$el.contains(e.target)) {
        this.disableSearchBox();
      }
    },
    handleSubmit() {
      utils.goToSearchURL('/search/topics', {
        q: utils.replaceSlash(this.current_keyword.trim())
      });
    }
  }
};
</script>
