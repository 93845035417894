<template>
  <input
    name="q"
    class="current_keyword_placeholder js-whole-search-input"
    @focus="showSearchBox"
    @input="updateCurrentKeyword"
    @keydown.up.prevent="handleUp"
    @keydown.down.prevent="handleDown"
    :value="current_keyword"
    type="text"
    placeholder="病名・症状・体の部位・お薬名など"
    role="ignore-empty-target"
  />
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { UNFOCUSED } from '../../search/utils';

export default {
  computed: {
    ...mapGetters([
      'current_keyword',
      'focus_candidate_index',
      'search_box_active'
    ])
  },
  watch: {
    focus_candidate_index: function(newIndex, _oldIndex) {
      if (this.search_box_active && newIndex === UNFOCUSED) {
        this.$el.focus();
      }
    }
  },
  methods: {
    ...mapActions([
      'updateKeywordAndSuggestions',
      'resetFocusCandidateIndex',
      'setFirstFocusCandidateIndex',
      'setLastFocusCandidateIndex',
      'enableSearchBox'
    ]),
    updateCurrentKeyword: function(e) {
      this.updateKeywordAndSuggestions(e.target.value);
    },
    showSearchBox() {
      this.enableSearchBox();
      this.resetFocusCandidateIndex();
    },
    handleUp() {
      this.setLastFocusCandidateIndex();
    },
    handleDown() {
      this.setFirstFocusCandidateIndex();
    }
  }
};
</script>
